import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/fizmdDqhKVo">
        <SEO title="Celebration - Searching for the Perfect Christmas" />
    </Layout>
)

export default SermonPost
